window.addEventListener('load', (event) => {
  load();
});

const load = () => {
  const showLoadingBtns = document.querySelectorAll('.show-loading-btn');
  showLoadingBtns.forEach((btn) => {
    btn.addEventListener('click', showLoading);
  });
};

const showLoading = () => {
  const loading = document.getElementById('loading-mask');
  loading.style.display = 'block';
};
